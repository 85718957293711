import { cache } from './consts';
import { dataAPI } from './api';
import { isValidURL, isValidLaptime } from './helpers';
import { loadSetups } from './setup_table';
import { populateTracksDropdown } from './tracks';
import { populateCarsDropdown } from './cars';
import { logout } from './login';

const MAX_FILE_SIZE = 192 * 1024;

$('#setupFile').on('change', event => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();

    reader.onload = event => {
      const contents = event.target.result;
      // TODO: automatically select track and car from the setup file
    };

    reader.readAsText(file);
  }
});

$(document).on('input', '.setupLaptimeInput', event => {
  let el = $(event.target);
  let input = el.val().
    replace(/\D/g, '').
    replace(/^0*/g, '');
  input = input.padStart(6, '_');
  let formattedInput = '_:__:___';

  let offset = 0;
  if (input.length > 6) {
    offset = 1;
  }
  formattedInput = input.substring(0, 1+offset) + ":" + input.substring(1+offset, 3+offset) + ":" + input.substring(3+offset);

  el.val(formattedInput);
});

const validateSetupUpload = (modalID) => {
  return new Promise((resolve, reject) => {
    let error = {message: [], el: []};

    if (modalID === 'uploadModal') {
      const fileInput = $(`#${modalID} .setupFileInput`)[0];
      if (fileInput.files.length == 0) {
        error.message.push(`A setup file must be selected`);
        error.el.push($(`#${modalID} .setupFileInput`));
      } else if (fileInput.files[0].size > MAX_FILE_SIZE) {
        error.message.push(`Loaded file is larger than ${MAX_FILE_SIZE/1024}KB`)
        error.el.push($(`#${modalID} .setupFileInput`));
      }
    }

    const youtubeInput = $(`#${modalID} .setupVideoInput`).val();
    if (youtubeInput.length > 0 && !isValidURL(youtubeInput)) {
      error.message.push(`Given Video Link is not a valid URL`);
      error.el.push($(`#${modalID} .setupVideoInput`));
    }

    const selectedCar = $(`#${modalID} .setupCarInput + ul.dropdown-menu .dropdown-item.selected`);
    if (selectedCar.length == 0) {
      error.message.push(`A car has to be selected`);
      error.el.push($(`#${modalID} .setupCarInput`));
    }

    const selectedTrack = $(`#${modalID} .setupTrackInput + ul.dropdown-menu .dropdown-item.selected`);
    if (selectedTrack.length == 0) {
      error.message.push(`A track has to be selected`);
      error.el.push($(`#${modalID} .setupTrackInput`));
    }

    const laptimeInput = $(`#${modalID} .setupLaptimeInput`).val();
    if (laptimeInput == 0 || !isValidLaptime(laptimeInput)) {
      error.message.push(`Laptime is required or is in invalid form`);
      error.el.push($(`#${modalID} .setupLaptimeInput`));
    }

    const selectedType = $(`#${modalID} .setupTypeInput + ul.dropdown-menu .dropdown-item.selected`);
    if (selectedType.length == 0) {
      error.message.push(`A setup type has to be selected`);
      error.el.push($(`#${modalID} .setupTypeInput`));
    }

    const selectedCondition = $(`#${modalID} .setupConditionInput + ul.dropdown-menu .dropdown-item.selected`);
    if (selectedCondition.length == 0) {
      error.message.push(`A track condition has to be selected`);
      error.el.push($(`#${modalID} .setupConditionInput`));
    }

    if (error.message.length > 0) {
      reject(error);
      return;
    }

    resolve();
  });
}

$(document).on('click', '#createSetupSave', () => {
  console.log('foo')
  handleSave('uploadModal');
});

$(document).on('click', '#editSetupSave', () => {
  handleSave('editModal');
});

const handleSave = (modalID) => {
  clearNotifications();
  clearValidationErrors();

  validateSetupUpload(modalID)
    .then(() => {
      const data = getSetupUploadData(modalID);
      const fileInput = $(`#${modalID} .setupFileInput`)[0];
      const file = fileInput.files[0];

      if (file) {
        readFileContent(file)
          .then(fileContent => {
            data.file_content = fileContent;
            return submitSetupUpload(modalID, data);
          })
          .catch(handleError);
      } else {
        submitSetupUpload(modalID, data).catch(handleError);
      }
    })
    .catch(validationErrorHandler);
}

const getSetupUploadData = (modalID) => {
  return {
    setup_id: $(`#${modalID} .setupID`).attr('data-setup_id'),
    car_id: $(`#${modalID} .setupCarInput + ul.dropdown-menu .dropdown-item.selected`).attr('data-item_id'),
    track_id: $(`#${modalID} .setupTrackInput + ul.dropdown-menu .dropdown-item.selected`).attr('data-item_id'),
    file_name: $(`#${modalID} .setupFileInput`)[0].files[0]?.name || '',
    file_content: '',
    laptime: $(`#${modalID} .setupLaptimeInput`).val(),
    type: $(`#${modalID} .setupTypeInput + ul.dropdown-menu .dropdown-item.selected`).attr('data-item_id'),
    condition: $(`#${modalID} .setupConditionInput + ul.dropdown-menu .dropdown-item.selected`).attr('data-item_id'),
    description: $(`#${modalID} .setupDescriptionInput`).val(),
    video_link: $(`#${modalID} .setupVideoInput`).val(),
  };
};

const submitSetupUpload = (modalID, data) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true,
  };

  const method = modalID === 'uploadModal' ? 'post' : 'put';
  const url = method === 'post' ? '/setups' : `/setups/${data.setup_id}`;

  return dataAPI[method](url, data, config)
    .then(response => {
      $(`#${modalID} .setup-form`).hide();
      $(`#${modalID} .setupSave`).hide();
      addFormNotification('Setup has been saved!');
      loadSetups();
    });
};

const handleError = (error) => {
  if (error.status === 401) {
    addFormNotification('You must be logged in to perform this action', true);
    logout();
  } else {
    addFormNotification('Failed to upload setup - try again', true);
  }
  console.error('Error:', error);
};

const validationErrorHandler = (error) => {
  console.error('Validation error:', error);
  error.message.forEach(msg => {
    addFormNotification(msg, true);
  });
  error.el.forEach(item => {
    item.addClass('input-error border-danger');
  });
};

const readFileContent = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = event => {
      resolve(event.target.result);
    };

    reader.onerror = event => {
      reject('File could not be read! Code ' + event.target.error.code);
    };

    reader.readAsText(file);
  });
}

const clearNotifications = () => {
  $('.setup-upload-status').empty();
}

const clearValidationErrors = () => {
  $('.input-error').removeClass('input-error border-danger');
}

const addFormNotification = (msg, error) => {
  $('.setup-upload-status')
    .append($('<div></div>')
      .addClass('notif-message alert alert-' + (error ? 'danger' : 'primary'))
      .text(msg)
    );
}
