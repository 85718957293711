import { authURL } from './consts';
import { authAPI } from './api';
import { getURLParam } from './helpers';

$('.discord-login').on('click', event => {
  authAPI.get(`/auth/discord`)
    .then(response => {
      window.location.href = response.data.url;
    })
    .catch(error => {
      console.error('get discord auth url', error);
    })
});

export const loginRedirect = () => {
  let data = {
    state: getURLParam('state'),
    code: getURLParam('code')
  }

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // Using plain fetch to do the request, since cross-site http-only cookies are
  // not handled properly by axios:
  // https://github.com/axios/axios/issues/2149
  fetch(`${authURL}/auth/discord`, {
    'method': 'POST',
    'body': JSON.stringify(data),
    'credentials': 'include',
    'mode': 'cors',
    'headers': {
      'content-type': 'application/json',
    },
  })
    .then(response => {
      if (!response.ok) {
        return response.text().then(errorText => {
          throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
        });
      }
      return response.json();
    })
    .then(data => {
      localStorage.setItem('user', JSON.stringify(data));

      window.location.href = window.location.origin;
    })
    .catch(error => {
      console.error('Error:', error);
    });
}

export const logout = () => {
  localStorage.removeItem('user');
  noLogin();
  location.reload();
}

export const getLoggedInUser = () => {
  const user = localStorage.getItem('user');
  if (!user) {
    return false;
  }

  return JSON.parse(user);
}

export const checkLogin = user => {
  // TODO: check the login state against the server and attempt to refresh token if no longer available
  userLoggedIn(user);
}

const userLoggedIn = user => {
  $('.discord-login').addClass('d-none');
  $('.user-welcome').removeClass('d-none');
  let name = typeof user.display_name === undefined || user.display_name === '' ? user.username : user.display_name;
  $('.user-welcome b').text(name);

  $('.newsetup-container').removeClass('d-none');
}

const noLogin = () => {
  $('.discord-login').removeClass('d-none');

  $('.user-welcome').addClass('d-none');
  $('.user-welcome b').text('');
  $('.newsetup-container').addClass('d-none');
}
