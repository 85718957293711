import { baseURL, cache } from './consts';
import { addDropdownItem, addMultiSelectDropdownItem } from './dropdowns';

// Obtains all tracks from the API, populates the tracks dropdown with all the
// tracks obtained, and creates an ID to object mapping in the cache
export const loadTracks = async () => {
  console.debug('load tracks');
  return axios.get(`${baseURL}/tracks`)
    .then(response => {
      cache.tracks = [];

      response.data.forEach(item => {
        cache.tracks[item.id] = item;
      });

      const $dropdown = $('.tracks-dropdown .dropdown-menu');
      populateTracksDropdown($dropdown, true);

      console.debug('processed tracks');
    })
    .catch(error => console.error('load tracks:', error));
}

export const populateTracksDropdown = ($dropdown, addAll) => {
  $dropdown.empty();

  const isSingleSelect = $dropdown.closest('.single-select-dropdown').length > 0;
  const isMultiSelect = $dropdown.closest('.multi-select-dropdown').length > 0;

  if (addAll) {
    if (isSingleSelect) {
      addDropdownItem($dropdown, 'all', 'All');
    }

    if (isMultiSelect) {
      addMultiSelectDropdownItem($dropdown, 'allTracks', 'All');
    }
  }

  Object.values(cache.tracks).forEach(item => {
    const itemId = item.id;
    const itemName = `${item.track_name} (${item.year})`;
    const itemImage = `/assets/images/flags/${item.country}.svg`;

    if (isSingleSelect) {
      addDropdownItem($dropdown, itemId, itemName, itemImage, item.track_name);
    }

    if (isMultiSelect) {
      addMultiSelectDropdownItem($dropdown, itemId, itemName, itemImage, item.track_name);
    }
  });
}
